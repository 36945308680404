import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import Search from '../components/Search';
import SectionTitle from '../components/SectionTitle';
import BingeCard from '../components/BingeCard';


const BingesPage = ({ data }) => {
  const binges = data.binges.nodes;

  const [sortType, setSortType] = useState({'type':'chrono','direction':'acs'});
  const [bingesList, setBingesList] = useState(binges);

  useEffect(() => {
      setSortType({'type':'alpha','direction':'acs'});
      document.querySelector('.view-options > button').dataset.dir = 'acs'; 
  },[]); 

  useEffect(() => {

    const sortArray = (type, direction) => {

      if (type === 'length') {
        if (direction === 'acs') {
          setBingesList([...bingesList].sort((a, b) => a.duration - b.duration));
        } else {
          setBingesList([...bingesList].sort((a, b) => b.duration - a.duration));
        }
      } else {
        if (direction === 'acs') {
          setBingesList([...bingesList].sort((a, b) => a.name.localeCompare(b.name)));
        } else {
          setBingesList([...bingesList].sort((a, b) => -1 * a.name.localeCompare(b.name)));
        }
      }

    };
    sortArray(sortType.type, sortType.direction);
  // eslint-disable-next-line
  },[sortType]);

  return (
    <main>
      <Helmet title={`All Binges`} />

      <Search />

      <SectionTitle title={`All Binges`} style={`primary`} sortType={sortType} setSortType={setSortType} hideChrono={true} hideRelease={true} />

      <ul className="binges-list">
        {bingesList.map((binge) => (
          <li className="binges-list__item" key={binge.id}>
            <BingeCard name={binge.name} slug={binge.slug} breakdown={binge.breakdown} duration={binge.duration} image={binge.bingeCoverImages?.childImageSharp.fluid} />
          </li>
        ))}
      </ul>
    </main>
  )
}

export default BingesPage

export const query = graphql`
  query {
    binges: allMongodbBingesData(
      sort: {
        fields: [name]
        order: ASC
      }
    ) {
      nodes {
        id
        name
        slug
        breakdown
        duration
        items {
          id
        }
        bingeCoverImages {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;