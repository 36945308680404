import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import formatTime from '../utils/formatTime';


export default function BingeCard({ name, slug, breakdown, duration, image }) {

    return (
      <Link className="binge-card" to={`/binges/${slug}`}>
        <div className="binge-card__text">
          <h3>{name}</h3>
          <p>{breakdown}</p>
        </div>
        <div className="binge-card__cta">
          <div className="binge-card__duration" dangerouslySetInnerHTML={{__html: formatTime(duration)}}></div>
          <svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512">
            <path d="M310.627 438.627l160-160c12.497-12.496 12.497-32.758 0-45.255l-160-160c-12.497-12.496-32.758-12.496-45.255 0s-12.497 32.758 0 45.255l105.373 105.373h-306.745c-17.673 0-32 14.327-32 32s14.327 32 32 32h306.745l-105.373 105.373c-6.248 6.248-9.372 14.438-9.372 22.627s3.124 16.379 9.372 22.627c12.497 12.497 32.758 12.497 45.255 0z"></path>
          </svg>
        </div>
        <Img className="binge-card__image" fluid={image} alt={name} />
      </Link>
    );
  }
  