import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import formatTime from '../utils/formatTime';

export default function Search() {

  const queryData = useStaticQuery(graphql`
    query {
      localSearchBinges {
        index
        store
      }
    }
  `)

  const index = queryData.localSearchBinges.index
  const store = queryData.localSearchBinges.store

  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);

    return (
      <div className="binges-search">

        <input type="search" placeholder="Search for a binge..." onChange={(e) => {
          setQuery(e.target.value);
        }} />

        <ul className="binges-search__results">
          {results.map(result => (
            <li key={result.id}>
              <Link to={`/binges/${result.slug}`}>
                <div className="binge-result__name">{result.name}</div>
                <div className="binge-result__breakdown">{result.breakdown}</div>
                <div className="binge-result__duration" dangerouslySetInnerHTML={{__html: formatTime(result.duration)}}></div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  